import React, { useCallback, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '@/components'
import { homePath } from '@/utils'

import { navItems } from './constants'

import logoImg from '@/assets/images/logo.svg'

export const AppHeader: React.FC = () => {
  const [show, setShow] = useState(false)

  const toggle = useCallback(() => {
    document.body.classList.toggle('scroll-lock')
    setShow(prev => !prev)
  }, [])

  return (
    <header>
      <nav className="bg-white border-gray-200">
        <div className="container flex flex-wrap items-center justify-between mx-auto py-4 px-4">
          <Link
            to={homePath}
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={logoImg} className="h-8" alt="Education Logo" />
          </Link>
          <Button
            type="button"
            onClick={toggle}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden outline-none"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </Button>
          <div
            className={classNames(
              'fixed inset-0 bg-white z-50 flex flex-col justify-center items-center md:relative md:flex md:w-auto md:bg-transparent',
              {
                hidden: !show,
              },
            )}
          >
            <Button
              onClick={toggle}
              className="absolute top-4 right-4 text-gray-500 hover:bg-gray-100 p-2 rounded-lg md:hidden outline-none"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </Button>
            <ul className="font-medium flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-8 rtl:space-x-reverse items-center">
              {navItems.map((nav, idx) => (
                <li key={idx}>
                  <NavLink
                    to={nav.path}
                    onClick={() => {
                      if (!!nav.element) {
                        const timer = setTimeout(() => {
                          const element = document
                            // @ts-ignore
                            .getElementById(nav.element)
                          element?.scrollIntoView({ behavior: 'smooth' })
                          clearTimeout(timer)
                        }, 200)
                      }
                    }}
                    className={nav.className}
                  >
                    {nav.defaultTitle}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
