import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useFinishCourse, useRescheduleCourse } from '@/apis'
import { myTestsPath, setStorageValue } from '@/utils'
import { IStartedProgress, TFunc } from '@/types'
import { useFeedback, useToast } from '@/hooks'
import { QUIZ_ID_KEY } from '@/constants'
import { Button } from '@/components'

import { ConfirmationModal } from './confirmationModal'
import { Player } from '../Admin/shared/components'
import { ChooseDate } from './chooseDate'

interface ICourseWidget {
  onClose: TFunc
  onRefresh: TFunc
  plan: IStartedProgress
}

export const CourseWidget: React.FC<ICourseWidget> = ({
  plan,
  onClose,
  onRefresh,
}) => {
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { toggleFeedbackView } = useFeedback()
  const [isActive, setIsActive] = useState(0)
  const [chooseDate, setChooseDate] = useState(false)
  const [isOpenStart, setIsOpenStart] = useState(false)

  const { mutateAsync: finishCourse } = useFinishCourse()
  const { mutateAsync: rescheduleCourse } = useRescheduleCourse()

  const onToggleStart = useCallback(() => {
    setIsOpenStart(prev => !prev)
  }, [])

  const _onClose = useCallback(() => {
    onClose()
    onRefresh()
    setIsOpenStart(false)
  }, [onClose, onRefresh])

  const onFinish = useCallback(
    async (date: string) => {
      const request = plan.reschedule ? rescheduleCourse : finishCourse
      const response = await request({
        date,
        courseId: plan.item._id,
      })
      if (response.data.success) {
        addToast('success', 'You have successfully completed your course')
        if (!plan.item.quiz) {
          _onClose()
        } else {
          onToggleStart()
        }
        if (!plan.reschedule) {
          toggleFeedbackView(true, plan.item._id)
        }
      }
    },
    [
      _onClose,
      addToast,
      onToggleStart,
      finishCourse,
      plan.item._id,
      plan.item.quiz,
      plan.reschedule,
      rescheduleCourse,
      toggleFeedbackView,
    ],
  )

  const onOpenModule = useCallback(
    (id: number) => () => {
      setIsActive(id)
    },
    [],
  )

  const onCloseDate = useCallback(() => {
    setChooseDate(false)
  }, [])

  const onStartQuiz = useCallback(() => {
    _onClose()
    setStorageValue(QUIZ_ID_KEY, plan.item.quiz?._id, false)
    setTimeout(() => {
      navigate(myTestsPath)
    }, 200)
  }, [_onClose, navigate, plan.item.quiz?._id])

  return (
    <div className="w-full bg-white p-4">
      {chooseDate && <ChooseDate onClose={onCloseDate} onChoose={onFinish} />}
      {!isOpenStart && (
        <ConfirmationModal
          onClose={_onClose}
          buttonText="Start now"
          onConfirm={onStartQuiz}
          description="You have a quiz linked to the current course. You can start it now."
        />
      )}
      <h2 className="text-2xl font-semibold mb-6">{plan?.item.title}</h2>
      <div className="grid grid-rows-1 grid-flow-col gap-4 mb-4">
        <div className="rounded border col-span-9">
          {plan.item?.modules?.[isActive]?.contents?.map?.(content => (
            <div key={content.id} className="shadow-sm">
              <div className="px-5 rounded">
                <div className="accordion-content pt-0 overflow-hidden transition">
                  <div className="accordion-header transition flex space-x-5 items-center h-16 items-center justify-between">
                    <div className="flex gap-4">
                      <h3>{content.title}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Player contentId={content.id} />
            </div>
          ))}
        </div>
        <div className="rounded border col-span-2 col-span-3">
          <ul className="p-4">
            <li className="text-2xl font-semibold mb-2">Modules</li>
            {plan?.item?.modules.map((item, idx) => (
              <li className="mb-2" key={idx}>
                <Button
                  disabled={idx === isActive}
                  className={classNames(
                    'w-full block text-black font-bold py-2 px-4 rounded hover:bg-green-300 flex items-center justify-between',
                    {
                      'bg-zinc-100': idx !== isActive,
                      'bg-green-300': idx === isActive,
                    },
                  )}
                  onClick={onOpenModule(idx)}
                >
                  <div>{item.name}</div>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex">
        {plan.reschedule && (
          <div>
            <Button
              onClick={() => setChooseDate(true)}
              className="bg-green-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-green-600"
            >
              Reschedule
            </Button>
          </div>
        )}
        <div className="w-full flex justify-end">
          <Button
            onClick={onClose}
            className="bg-red-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-red-600"
          >
            Leave
          </Button>
          {!plan.completed && (
            <Button
              onClick={() => setChooseDate(true)}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Finish
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
