import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { ITask, TTaskStatus } from '@/types'
import { generateFilePath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { useGetDiploma } from '@/apis'
import { Button } from '@/components'
import classNames from 'classnames'

export const HistoryRow: React.FC<ITask> = props => {
  const [task, setTask] = useState<ITask>()

  useEffect(() => {
    setTask(props)
  }, [props])

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath) as string, '__blank')
    },
    [],
  )

  const onGetDiploma = useCallback((data: any) => {
    const blob = new Blob([data])

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element to trigger the download
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = 'diploma.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const [getDiploma] = useGetDiploma(onGetDiploma)

  const onDownloadDocument = useCallback(
    (documentId: string) => {
      getDiploma({
        queryParams: [
          {
            documentId,
          },
        ],
      })
    },
    [getDiploma],
  )

  // @ts-ignore
  const { _id, date, createdBy, path, plan, diplomaPath } = useMemo(
    () => task ?? {},
    [task],
  )

  if (!_id) return null

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{createdBy.name}</td>
      <td className="py-2 px-4 border-b text-start">
        {createdBy.affiliate.name ?? '-'}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {plan.title ?? '-'} ({plan.type})
      </td>
      <td className="py-2 px-4 border-b text-start">
        {path ? (
          <Button
            onClick={openFile(path)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Result
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {diplomaPath ? (
          <Button
            onClick={() => onDownloadDocument(_id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Download
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(date).format(DATE_FORMAT)}
      </td>
      <td className="py-2 px-4 border-b text-start capitalize">
        <span
          className={classNames('p-2 rounded text-white', {
            'bg-red-400': [TTaskStatus.CANCELED, TTaskStatus.REJECTED].includes(
              task?.status as TTaskStatus,
            ),
            'bg-green-400': [
              TTaskStatus.ACCEPTED,
              TTaskStatus.IN_REVIEW,
            ].includes(task?.status as TTaskStatus),
          })}
        >
          {task?.status}
        </span>
      </td>
    </tr>
  )
}
