import { IconType } from '@/components/Icon/types'
import { IPlan, IUser } from './response'

export type BasePlacement = 'top' | 'right' | 'bottom' | 'left'

export type VariantPlacement =
  | 'top-start'
  | 'top-end'
  | 'top-center'
  | 'bottom-start'
  | 'bottom-end'
  | 'bottom-center'
  | 'right-start'
  | 'right-end'
  | 'right-center'
  | 'left-start'
  | 'left-end'
  | 'left-center'

export type Placement = BasePlacement | VariantPlacement

export type Rect = Pick<
  DOMRect,
  'left' | 'top' | 'bottom' | 'right' | 'height' | 'width'
>

export type TFunc = () => void

export const roles = [
  'Admin',
  'Student',
  'Teacher',
  'Anonymous',
  'AffiliateOwner',
  'Proctor',
  'Manager',
]

export const publicRoles = ['Proctor', 'Student']

export enum IRole {
  Admin,
  Student,
  Teacher,
  Anonymous,
  AffiliateOwner,
  Proctor,
}

export enum TConsultationModal {
  ALLOW = 'allow',
  REJECT = 'reject',
}

export enum RequestStatuses {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export enum PaymentSchemaTypes {
  QUIZ = 'Quiz',
  COURSE = 'Course',
  AFFILIATE = 'Affiliate',
  SUBMISSION = 'SubmissionForm',
}

export interface ISignUpPayload {
  role: IRole
  name: string
  email: string
  password: string
  subDomain?: string
  inviterCode?: string
}

export interface ISignInPayload {
  email: string
  password: string
  subDomain?: string
}

export interface IForgotPayload {
  email: string
  subDomain?: string
}

export interface IResetPayload {
  token: string
  password: string
  confirmPassword: string
}

export interface IDecodedToken {
  exp: number
  sub: number
  iat: number
  id: string
  role: IRole
  name: string
  email: string
}

export interface IOption<T = string> {
  label: string
  value: T
  icon?: IconType
}

export interface IDiplomaPayload {
  documentId: string
}

export interface IEnrollmentPayload {
  bio?: string
  name: string
  city: string
  email: string
  user?: string
  state: string
  gender: string
  zipCode: string
  address: string
  dateOfBirth: Date
  phoneNumber: string
  educationalBackground: string
  emergencyContact?: {
    phone: string
    email: string
    lastName: string
    firstName: string
    relationship: boolean
  }
}

export interface ICheckCoursePayload {
  courseId: string
}

export interface IListItem {
  title: string
  contentId: string
  mainLibrary: string
}

export interface IModule {
  id: number
  name: string
  contents: {
    id: string
    title: string
  }[]
}

export interface ITest {
  id: string
  title: string
  passed: boolean
}

export interface ICreateCoursePayload {
  _id?: string
  quiz?: string
  price: number
  title: string
  visible: boolean
  modules: IModule[]
  description: string
  visibleFor?: string[]
}

export enum TProgress {
  COURSE = 'Course',
  QUIZ = 'Quiz',
}

export enum IStatus {
  Enrolled = 'enrolled',
  Completed = 'completed',
  Disabled = 'disabled',
}

export enum TTaskStatus {
  IN_REVIEW = 'in_review',
  ACCEPTED = 'accepted',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
}

export enum TTaskType {
  TRANSCRIPT = 'Transcript',
}

export interface ITask {
  _id: string
  plan: IPlan
  user: IUser
  date: string
  path: string
  ready: boolean
  type: TTaskType
  createdAt: Date
  createdBy: IUser
  diplomaPath: string
  status: TTaskStatus
  meetingLink?: string
}
export interface IStartedProgress {
  item: IPlan
  user: IUser
  passed: boolean
  percent: number
  type: TProgress
  status: IStatus
  completed: boolean
  reschedule?: boolean
}

export interface IFinishPayload {
  date: string
  courseId: string
}

export interface IFinishQuizPayload extends IQuizActionPayload {
  date: string
}

export interface IMessage {
  _id: string
  chat: IChat
  text: string
  isRead: boolean
  createdAt: string
  user: Pick<IUser, '_id' | 'avatar' | 'email' | 'name' | 'role'>
}

export interface IChat {
  _id: string
  lastMessage: string
  unreadCounts: number
  sender: Pick<
    IUser,
    '_id' | 'avatar' | 'email' | 'name' | 'role' | 'enrollment'
  >
  receiver: Pick<
    IUser,
    '_id' | 'avatar' | 'email' | 'name' | 'role' | 'enrollment'
  >
}

export interface IMessagesPayload {
  chatId: string
}

export interface IFeedback {
  user: IUser
  plan: IPlan
  comment: string
  createdAt?: string
}

export interface IFeedbackPayload {
  course: string
  comment: string
}

export interface IPageMeta {
  page: number
  pageSize: number
}

interface PersonalDetails {
  name: string
  email: string
  phone: string
  gender: string
  dateOfBirth: Date
}

export interface Reference {
  name: string
  contactDetails: {
    email: string
    phone: string
  }
}

export interface EducationalBackground {
  degree: string
  institution: string
  fieldOfStudy: string
  graduationYear: number
}

export interface ISubmissionForm {
  _id?: string
  createdAt?: string
  certificatePath: string
  personalDetails: PersonalDetails
  references?: Reference[]
  supportingDocuments?: string[]
  paymentDetails: {
    amount?: number
    paidId?: string
    isConfirmed?: boolean
    paymentLink?: string
  }
  accepted: boolean
  declined: boolean
  paymentEmail: string
  detailedProposal: string
  professionalBackground?: string[]
  educationalBackground?: EducationalBackground[]
}

export interface IQuizActionPayload {
  id: string
}

export interface IH5PPlayerProgressOptions {
  init?: boolean
  onGetProgress?: (_progress: number) => void
  onUpdateProgress?: (_progress: number) => void
}

export interface IQuestion {
  answers: number[]
}

export interface IQuestionSetAnswers {
  answers: IQuestion[]
}

export interface IMultiChoiceAnswers extends IQuestion {}

export interface IUpdateCourseAccessibilityPayload {
  canEdit: boolean
  courseId: string
  visibleForAffiliates: boolean
}

export interface IUpdateQuizAccessibilityPayload {
  id: string
  canEdit: boolean
  visibleForAffiliates: boolean
}

export interface IAffiliate {
  _id: string
  name: string
  owner: IUser
  postCode: string
  bankName: string
  subDomain: string
  isActive: boolean
  websiteUrl: string
  bankAddress: string
  contact: IContactDto
  createdAt: Date
  companyName: string
  companyNumber: string
  bankBranchAddress: string
}

export interface IAffiliateDetails extends IAffiliate {
  users: number
  courses: number
  quizzes: number
  payments: number
}

export interface IColor {
  title: string
  menuItem: string
  description: string
  activeButton: string
}

export enum TSocial {
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export enum SectionTypes {
  // templateN
  REWARD = 'reward',
  ADVANCE = 'advance',
  ADVANTAGES = 'advantages',
  // Default template
  WHAT_WE_DO = 'whatWeDo',
  OUR_MISSION = 'ourMission',
  WHAT_WE_OFFER = 'whatWeOffer',
  TESTIMONIALS = 'testimonials',
  CONTACT_US = 'contactUs',
  JOIN_TODAY = 'joinToday',
}

export enum ConsultationStatuses {
  INPROGRESS,
  ALLOWED,
  REJECTED,
}

export interface ISectionBlock {
  title: string
  image: string
  description?: string
  block?: Omit<ISectionBlock, 'image' | 'block'>
}

export interface ISection {
  title?: string
  image?: string
  videoURL?: string
  type: SectionTypes
  description?: string
  blocks?: ISectionBlock[]
}

export interface ISocial {
  link: string
  type: TSocial
}

export interface ITemplate {
  _id: string
  image: string
  title: string
  name: Templates
  mainVideo: string
  socials: ISocial[]
  description: string
  sections: ISection[]
}

export interface IAffiliateBrand {
  logo: string
  color: IColor
  fontFamily: string
  webSiteLink?: string
  affiliate: IAffiliate
  templates: ITemplate[]
  defaultBrand: Templates
}

export interface IFontFamily {
  family: string
}

export interface IConsultation {
  _id: string
  owner: IUser
  email: string
  token: string
  deletedAt: Date
  lastName: string
  firstName: string
  phoneNumber: string
  status: ConsultationStatuses
}

export interface ITab<T> {
  key: T
  label: string
}

export type ArgumentType = string | number | boolean | any

export enum Templates {
  TEMPLATE_1 = 'template_1',
  DEFAULT_TEMPLATE = 'default_template',
}

export enum CourseFilters {
  AFFILIATE,
  CENTRAL,
}

export interface IPaymentHistoryPayload {
  user?: string
  page?: number
  endDate?: Date
  amount?: number
  startDate?: Date
  pageSize?: number
  type?: PaymentSchemaTypes
}

export interface IPaymentStat {
  total: number
  type: PaymentSchemaTypes
  data: {
    [key: string]: number
  }
}

export interface IAvailableZone {
  to: Date
  from: Date
  user: IUser
  _id: string
  location: string
}

export interface IUpdateProfilePayload {
  name: string
}

export interface IChooseRolePayload {
  role: IRole
}

export interface ICreateCalendarPayload {
  to: string
  from: string
  location: string
}

export interface IUpdateCalendarPayload extends ICreateCalendarPayload {
  id: string
}

export interface IDeleteCalendarPayload {
  id: string
}

export interface IProcessTaskPayload {
  id: string
  status: TTaskStatus
}

export interface IBrandDetailsPayload {
  subDomain: string
}

export interface IConsultationPayload {
  token: string
}

export interface ICreateConsultationPayload {
  email: string
  lastName: string
  firstName: string
  phoneNumber: string
}

export interface ICreateAffiliateBrandPayload {
  logo?: string
  token: string
  color?: IColor
  affiliate: string
  fontFamily?: string
  webSiteLink?: string
  template?: ITemplate
  defaultBrand?: Templates
}

export interface IProcessConsultationPayload {
  id: string
  type: TConsultationModal
}

export interface ISetRolePayload {
  userId: string
  roles: IRole[]
}

export interface IContactDto {
  email: string
  lastName: string
  firstName: string
  phoneNumber: string
  mailingAddress: string
}

interface CreateAffiliatePayload {
  name: string
  bankName: string
  subDomain: string
  postCode?: string
  bankAddress: string
  websiteUrl?: string
  contact: IContactDto
  companyName?: string
  companyNumber?: string
  bankBranchAddress: string
}

export interface IAdminCreateAffiliatePayload extends CreateAffiliatePayload {
  email: string
}

export interface ICreateAffiliatePayload extends CreateAffiliatePayload {
  token: string
  owner: string
}

export interface IUpdateAffiliatePayload extends CreateAffiliatePayload {
  id: string
}

export interface IChooseTemplatePayload {
  template: Templates
}

export enum EFinanceTabs {
  Payments = 'payments',
  Incomes = 'incomes',
  Methods = 'methods',
}

export enum TIcome {
  UC = 'uc',
  REFERRAL = 'referral',
  AFFILIATE = 'affiliate',
}

export enum MethodType {
  card = 'card',
  // paypal = 'paypal',
}

export interface IAddPaymentMethodPayload {
  type: MethodType
  token: string
}

export interface IPaymentMethodPayload {
  id: string
}

export interface IUpdatePaymentMethodPayload
  extends IAddPaymentMethodPayload,
    IPaymentMethodPayload {}

export enum EPaymentMethodStatus {
  PENDING,
  CONNECTED,
}

export enum TPaymentMethod {
  STRIPE = 'Stripe',
  //
}

export interface IPaymentMethod {
  _id: string
  user: IUser
  isDefault: boolean
  method: TPaymentMethod
  status: EPaymentMethodStatus
}

export interface ICreateContactFormPayload {
  email: string
  message: string
  fullName: string
  subDomain?: string
}

export interface IUpdateMeetingLinkPayload {
  id: string
  meetingLink: string
}
