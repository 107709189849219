import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import { Button, Form, Modal, TextInput } from '@/components'
import { TChooseExamDateSchema, TFunc } from '@/types'
import { chooseExamDateForm } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { useHookForm } from '@/hooks'

interface IChooseDate {
  onClose?: TFunc
  onChoose: (_proctor: string) => void
}

export const ChooseDate: React.FC<IChooseDate> = ({ onClose, onChoose }) => {
  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(chooseExamDateForm)

  const onSubmit: SubmitHandler<TChooseExamDateSchema> = useCallback(
    async payload => {
      onChoose(moment(payload.date).format(DATE_FORMAT))
    },
    [onChoose],
  )

  return (
    <Modal isOpen canClose={false} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex flex-col sm:items-start"
        >
          <TextInput
            label="Select date"
            type="datetime-local"
            {...register('date')}
            error={errors.date?.message}
            min={moment()
              .add(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm')}
          />
          <div className="flex w-full justify-end">
            <Button
              type="submit"
              className="bg-green-300 text-black py-2 px-4 mb-5 rounded cursor-pointer hover:bg-green-500"
            >
              Select
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
